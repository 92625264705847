<template>
  <div class="">
    <el-form :model="info" label-position="left" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="类型" prop="card_type">
        <el-radio-group v-model="info.card_type">
          <el-radio :label="1">积分卡</el-radio>
          <el-radio :label="2">会员卡</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="积分数" prop="integral" v-if="info.card_type==1">
        <el-input v-model="info.integral" placeholder="请输入积分数"></el-input>
      </el-form-item>
      <el-form-item label="选择会员卡" prop="grade_id" v-if="info.card_type==2">
        <el-select v-model="info.grade_id" placeholder="请选择会员卡">
          <el-option
              v-for="item in gradeList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发卡数量" prop="sum">
        <el-input v-model="info.sum" placeholder="请输入数量"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">确认发卡</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gradeList: false,
      info: {
        card_type: '',
        integral: '',
        sum: '',
        grade_id: ''
      },
      rules: {
        card_type: [
          { required: true, message: '请选择卡类型', trigger: 'blur' }
        ],
        integral: [
          { required: true, message: '请输入积分数', trigger: 'blur' }
        ],
        sum: [
          { required: true, message: '请输入发卡数量', trigger: 'blur' }
        ],
        grade_id: [
          { required: true, message: '请选择会员卡', trigger: 'blur' }
        ]
      },
    }
  },
  components: {
  },
  props: {
    callback: Function
  },
  watch: {
  },
  created() {
    this.getGradeList()
  },
  mounted() {
  },
  methods: {
    getGradeList() {
      this.$api.member.memberGradeIndex({page_size:100},res => {
        if(res.errcode==0) {
          this.gradeList = res.data.data
        }
      })
    },
    submitForm() {
      var _this = this
      var params = this.info
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          _this.$api.activity.integralCardAdd(params, res => {
            if (res.errcode == 0) {
              _this.success(res.errmsg)
              _this.callback()
            } else {
              _this.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>
</style>
