<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-input v-model="search.keywords" placeholder="请输入名称" size="small" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <div class="mg-bt-10">
      <el-button type="primary" size="small" icon="el-icon-plus" @click="addGroup()">添加</el-button>
      <el-button type="danger" size="small" icon="el-icon-delete" @click="delGroup()">删除</el-button>
    </div>
    <el-table
        :data="tableData"
        ref="rfTable"
        border
        style="width: 100%"
        size="mini"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          align="center"
          width="55">
      </el-table-column>
      <el-table-column
          prop="card_type"
          label="类型">
        <template slot-scope="scope">
          {{scope.row.card_type==1?'积分卡':'会员卡'}}
        </template>
      </el-table-column>
      <el-table-column
          prop="content"
          label="内容">
        <template slot-scope="scope">
          <span v-if="scope.row.card_type==1">积分：{{scope.row.integral}}分</span>
          <span v-if="scope.row.card_type==2">会员卡：{{scope.row.grade_name}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="card_no"
          label="卡号">
      </el-table-column>
      <el-table-column
          prop="card_pwd"
          label="卡密">
      </el-table-column>
      <el-table-column
          prop="member_id"
          label="领取用户"
          align="center"
          width="240">
        <template slot-scope="scope">
          <div v-if="scope.row.member" class="member-box">
            <div>
              <div>{{scope.row.member.name}}（{{scope.row.member.user_id}}）</div>
              <div>{{scope.row.member.mobile}}</div>
            </div>
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
          prop="activate_time"
          label="领取时间"
          align="center"
          width="140">
      </el-table-column>
      <el-table-column
          prop="status"
          label="状态"
          align="center"
          width="140">
        <template slot-scope="scope">
          <span v-if="scope.row.status==0">未领取</span>
          <span v-if="scope.row.status==1">已领取</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="create_time"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
    </el-table>
    <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="total, prev, pager, next, jumper"
        :page-sizes="[10, 20, 30, 40]"
        :total="search.total">
    </el-pagination>
    <el-dialog
        title="发卡"
        :visible.sync="dialogVisible"
        :destroy-on-close="true"
        width="600px">
      <add-integral-card :callback="res => { dialogVisible=false; getList() }"></add-integral-card>
    </el-dialog>
  </div>
</template>
<script>

import AddIntegralCard from "@/components/AddIntegralCard";
export default {
  components: {
    AddIntegralCard
  },
  data() {
    return {
      dialogVisible: false,
      info: false,
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        keywords: ''
      },
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {},
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        keywords: that.search.keywords,
      }
      this.$api.activity.integralCardIndex(param, function (res) {
        that.hideLoading()
        if (res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    addGroup() {
      this.dialogVisible = true;
    },
    delGroup() {
      var that = this
      var sel = this.$refs.rfTable.selection
      if (sel.length < 1) {
        that.tips('请选择删除项')
        return false
      }
      var ids = []
      sel.forEach(function (item) {
        ids.push(item.id)
      })
      this.$confirm('确认要删除这些数据吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.activity.integralCarddel({ids: ids}, function (res) {
          if (res.errcode == 0) {
            that.success('删除成功')
            that.getList()
          } else {
            that.fail(res.errmsg)
          }
        })
      }).catch(() => {

      });
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
